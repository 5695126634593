import { graphql } from 'gatsby'
import Collection from '../containers/Collection.Author'

export default Collection

export const pageQuery = graphql`
  query allPostsByAuthorQuery($skip: Int!, $limit: Int!, $slug: String!) {
    collectionInfo: author(slug: { eq: $slug }) {
      ...Author
    }

    exchanges: allExchange(
      filter: {
        private: { ne: true }
        draft: {ne: true}
        author: { slug: { eq: $slug } }
      }
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...ExchangePreview
        ...ExchangeThumbnailRegular
        ...ExchangeLogo
      }
      ...ExchangePagination
    },

    wallets: allWallet(
      filter: {
        private: { ne: true }
        draft: {ne: true}
        author: { slug: { eq: $slug } }
      }
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...WalletPreview
        ...WalletThumbnailRegular
        ...WalletLogo
      }
      ...WalletPagination
    },

    comparisons: allComparison(
      filter: {
        private: { ne: true }
        draft: {ne: true}
        author: { slug: { eq: $slug } }
      }
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...ComparisonPreview
        ...ComparisonThumbnailRegular
      }
      ...ComparisonPagination
    },

    coupons: allCoupon(
      filter: {
        draft: {ne: true}
        author: { slug: { eq: $slug } }
      }
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...CouponPreview
      }
      ...CouponPagination
    },

    walletReviews: allWalletReview(
      filter: {
        private: { ne: true }
        draft: {ne: true}
        author: { slug: { eq: $slug } }
      }
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...WalletReviewPreview
        ...WalletReviewThumbnailRegular
        ...WalletReviewLogo
      }
      ...WalletReviewPagination
    }

    reviews: allReview(
      filter: {
        private: { ne: true }
        draft: {ne: true}
        author: { slug: { eq: $slug } }
      }
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...ReviewPreview
        ...ReviewThumbnailRegular
        ...ReviewLogo
      }
      ...ReviewPagination
    }
  }
`

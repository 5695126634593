import React from 'react'
import { Layout, Stack, Main, PreFooter } from '@layout'
import PageTitle from '@components/PageTitle'
import Pagination from '@components/Pagination'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import AuthorExpanded from '@widgets/AuthorExpanded'

const PageCollectionAuthors = ({
  data: { exchanges, walletReviews, comparisons, coupons, wallets, reviews, collectionInfo },
  ...props
}) => {

  const posts = {
    nodes: [],
    totalCount: 0,
  }

  exchanges.nodes.forEach(item => {
    posts.nodes.push(item)
    posts.totalCount += 1
  })

  comparisons.nodes.forEach(item => {
    posts.nodes.push(item)
    posts.totalCount += 1
  })

  coupons.nodes.forEach(item => {
    item.isCoupon = true
    posts.nodes.push(item)
    posts.totalCount += 1
  })

  walletReviews.nodes.forEach(item => {
    posts.nodes.push(item)
    posts.totalCount += 1
  })

  reviews.nodes.forEach(item => {
    posts.nodes.push(item)
    posts.totalCount += 1
  })

  wallets.nodes.forEach(item => {
    posts.nodes.push(item)
    posts.totalCount += 1
  })

  return (
    <Layout {...props}>
      <Seo title={collectionInfo.name} />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle header="Published by Author" totalCount={posts.totalCount} />
      </Stack>
      <Divider />
      <Stack>
        <Main>
          <AuthorExpanded author={collectionInfo} />
          <Divider />
          {posts.nodes && (
            <CardList
              nodes={posts.nodes}
              variant={['horizontal-md', 'vertical']}
              columns={[1, 2, 3, 3]}
            />
          )}
        </Main>
      </Stack>
      <Divider />
      <PreFooter>
        <Pagination {...posts.pageInfo} {...collectionInfo} />
      </PreFooter>
    </Layout>
  )
}

export default PageCollectionAuthors
